<script>
  import { spring } from "svelte/motion"

  let starter
  let innerWidth
  let parent
  let video
  let advancer
  let cursor
  let sentry
  let initiated = false
  let playbackTime = 0
  let firstStop = false
  let secondStop = false
  let thirdStop = false
  let mobile = false

  if (innerWidth >= 600) {
    video.autoplay = true
  }

  const mouseCoords = spring({ x: 0, y: 0 })
  const onMouseMove = (event) => {
    let bounds = parent.getBoundingClientRect()
    let xCord = event.clientX - bounds.left
    let yCord = event.clientY - bounds.top
    $mouseCoords = { x: xCord, y: yCord }
  }

  const checkStoppage = () => {
    if (!mobile) {
      if (playbackTime >= 7 && !firstStop) {
        handleStop()
        firstStop = true
      }
      if (playbackTime >= 13.5 && !secondStop) {
        handleStop()
        secondStop = true
      }
      if (playbackTime >= 21.5 && !thirdStop) {
        handleStop()
        thirdStop = true
      }
    }
  }

  const handleStop = () => {
    video.pause()
    advancer.classList.remove("hidden")
    cursor.classList.remove("invisible")
  }

  const handleStart = () => {
    initiated = true
    advancer.classList.add("hidden")
    starter.classList.add("hidden")
    cursor.classList.add("invisible")
    video.play()
  }

  const resetVideo = () => {
    firstStop = false
    secondStop = false
    thirdStop = false
    playbackTime = 0
    video.play()
  }

  //safari muckt da rum -> bestimmt auch noch andere browser
  const onScroll = () => {
    // let bounds = sentry.getBoundingClientRect()
    // let yOffset = bounds.top
    // if (yOffset <= 0 && !initiated && !mobile) {
    //   handleStart()
    // }
  }

  const isMobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      mobile = true
    } else {
      mobile = false
    }
  }
  // Asset Imports
  import posterImage from "../assets/images/poster.webp"
</script>

<svelte:window on:scroll={onScroll} on:load={isMobile} bind:innerWidth />
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div bind:this={parent} class="relative cursor-none group" on:mousemove={onMouseMove}>
  <div bind:this={sentry} class="absolute w-full h-0 bg-transparent"></div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    bind:this={video}
    bind:currentTime={playbackTime}
    on:timeupdate={checkStoppage}
    on:ended={resetVideo}
    class="w-full h-auto object-cover cursor-none"
    poster={posterImage.src}
    data-title="How it works"
  >
    <source src="/roadmap.mp4" type="video/mp4" />
    <source src="/roadmap.webm" type="video/webm" />
  </video>
  <button bind:this={advancer} class="absolute inset-0 cursor-none hidden select-none" on:click={handleStart}></button>
  <div
    bind:this={cursor}
    class="transition-opacity duration-500 group-hover:opacity-100 opacity-0 text-black absolute top-0 left-0 text-subline font-medium select-none pointer-events-none flex gap-1 items-center justify-center invisible bg-white size-28 rounded-full drop-shadow-cursor shadow-black/25"
    style:--x={`${$mouseCoords.x}px`}
    style:--y={`${$mouseCoords.y}px`}
    style="transform: translate(-50%, -50%) translate(var(--x, 0px), var(--y, 0px));"
  >
    <span>NEXT</span>
    <svg class="h-3 w-3" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 0L31 24L7 48H17.65L41.65 24L17.65 0H7Z" fill="currentColor"></path>
    </svg>
  </div>
  <button bind:this={starter} class="absolute inset-0 cursor-pointer flex justify-center items-center" on:click={handleStart}>
    <svg class="size-24 lg:size-32 drop-shadow-cursor" width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M512 256C512 397.385 397.385 512 256 512C114.615 512 0 397.385 0 256C0 114.615 114.615 0 256 0C397.385 0 512 114.615 512 256Z" fill="white" />
      <path d="M374 256L197 361.655V150.345L374 256Z" fill="black" />
    </svg>
  </button>
</div>
